import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import request from './plugins/axios.js'
import VueUeditorWrap from 'vue-ueditor-wrap';
import 'animate.css/animate.min.css';
import './assets/css/main.css'


createApp(App)
.use(router)
.use(ElementPlus,{locale: zhCn,})
.use(request)
.use(VueUeditorWrap)
.mount('#app')