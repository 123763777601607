import {
	createRouter,
	createWebHashHistory
} from 'vue-router'

const routes = [{
		path: '/',
		name: 'Login',
		component: () => import('../views/Login')
	},
	{
		path: '/Home',
		name: 'Home',
		component: () => import('../views/Home'),
		children: [{
				path: '/Home/Main',
				name: 'Home.Main',
				component: () => import('../views/Main/Index')
			},
			{
				path: '/Course',
				name: 'Course',
				component: () => import('../views/Main/Course/Index')
			},
			{
				path: '/User/Yhrz',
				name: 'User.Yhrz',
				component: () => import('../views/Main/User/Yhrz')
			},
			{
				path: '/User/Xqfzr',
				name: 'User.Xqfzr',
				component: () => import('../views/Main/User/Xqfzr')
			},
			{
				path: '/User/Bzr',
				name: 'User.Bzr',
				component: () => import('../views/Main/User/Bzr')
			},
			{
				path: '/User/Skjs',
				name: 'User.Skjs',
				component: () => import('../views/Main/User/Skjs')
			},
			{
				path: '/User/Xy',
				name: 'User.Xy',
				component: () => import('../views/Main/User/Xy')
			},
			{
				path: '/School',
				name: 'School',
				component: () => import('../views/Main/School/Index')
			},
			{
				path: '/Setting/Sklx',
				name: 'Setting.Sklx',
				component: () => import('../views/Main/Setting/Sklx')
			},
			{
				path: '/Shop/Item',
				name: 'Shop.Item',
				component: () => import('../views/Main/Shop/Item')
			},
			{
				path: '/Shop/Order',
				name: 'Shop.Order',
				component: () => import('../views/Main/Shop/Order')
			},
			{
				path: '/Shop/Fxjs',
				name: 'Shop.Fxjs',
				component: () => import('../views/Main/Shop/Fxjs')
			},
			{
				path: '/Statistic',
				name: 'Statistic',
				component: () => import('../views/Main/Statistic/Index')
			},
		]
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router