<template>
	<router-view/>
</template>


<script>
 export default {
 	name: 'APP',
 	data() {},
 	beforeMount() {
		
	},
	methods:{
		changeToken(){
			let that = this;
			let token = window.localStorage.getItem("token");
			if(token==undefined||token==null||token==''||token=='undefined'){
				that.$router.push("/");
				return false;
			}else{
				let url = "/sys/Sys_User/swapToken.action";
				that.$post(url, {})
					.then(res => {
						let result = res.data;
						if (result.msg == 2) {
							let token = result.token;
							let store = window.localStorage;
							store.setItem("token", token);
						} else {
							that.$router.push("/");
						}
					}, response => {
				
					});
			}
		}
	}
}
</script>

<style>
#app,body,html{
  width:100%;
  height:100%;
  padding:0px;
  margin:0px;
}
</style>
