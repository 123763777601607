import Axios from 'axios'
import Qs from 'qs'
import { ElMessage, ElMessageBox } from 'element-plus'


const baseURL = window.location.protocol+"//"+window.location.host;
const baseAPI = "/snh-api-webapp";
const axios = Axios.create({
    baseURL: baseURL+ baseAPI,
    timeout: 60*1000, // 证据上传时文件太大容易超时
    headers: {

    }
})
//axios.defaults.withCredentials = true // 是否携带请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
// 请求拦截器
axios.interceptors.request.use(config => {
	let token = window.localStorage.getItem("token");
	if(token==undefined){
		token = "";
	}
	config.headers.token = token;
    if (config.method === 'post') {
        if(config.processData==undefined){
           // config.data.ACTION_EXCLUDE = '';
            let bakData = config.data;
            config.data = Qs.stringify(config.data) || bakData
        }
    }
    return config
}, error => {
    console.log('网络异常，请稍后！');
    return Promise.reject(error)
})

let hasTips = false;
// 响应拦截器
axios.interceptors.response.use(res => {
	let that = this;
	let code = res.data.code;
	if(code==1){//登录过期
	    if(!hasTips){
			hasTips = true;
			ElMessageBox.alert('登录已过期，请重新登录系统！', '友情提示', {
			    showClose:false,
				closeOnClickModal:false,
			    confirmButtonText: '确定',
			    callback: () => {
					window.localStorage.removeItem("token");
					window.location.href = '/';
			    },
			});
		}
		return  Promise.reject(res);
	}
	else if(code==2){//系统错误
		console.log("系统错误");
		return  Promise.reject(res);
	}
	return res
}, error => {
    console.log('网络异常，请稍后！');
    return error;
})

export default {
    install: (app, options) => {
        app.config.globalProperties.$post = (url,params,config) => {
            return axios.post(url, params, config)
        },
		app.config.globalProperties.$uploadurl = ()=>{
			return baseURL + baseAPI +"/common/oss/upload.action";
		},
		app.config.globalProperties.$baseurl = ()=>{
			return baseURL + baseAPI + "/";
		},
		app.config.globalProperties.$upload = (url, formData, uploadProcess,headers) => {
		  return axios.post(url, formData, {
			headers,
			onUploadProgress: progressEvent => {
			  let percent = (progressEvent.loaded / progressEvent.total * 100 | 0)
			  uploadProcess && uploadProcess(percent)
			}
		  })
		},
		app.config.globalProperties.$formatDate = (date)=>{
			let Year = 0;
			let Month = 0;
			let Day = 0;
			let CurrentDate = "";	
			Year = date.getFullYear();//ie火狐下都可以
			Month = date.getMonth()+1;
			Day = date.getDate();
			CurrentDate += Year + "-";
			if (Month >= 10 )
			{
			    CurrentDate += Month + "-";
			}
			else
			{
			    CurrentDate += "0" + Month + "-";
			}
			if (Day >= 10 )
			{
			    CurrentDate += Day;
			}
			else
			{
			    CurrentDate += "0" + Day;
			}
			return CurrentDate;
		},
		app.config.globalProperties.$timediff = (begin_time, end_time)=>{
		  //年月日时分秒转换为时间戳
		  let beginTime = begin_time / 1000;
		  let endTime = end_time / 1000;
		  let starttime = ''
		  let endtime = ''
		  if (beginTime < endTime) {
		    starttime = beginTime;
		    endtime = endTime;
		  } else {
		    starttime = endTime;
		    endtime = beginTime;
		  }
		  //计算天数
		  let timediff = endtime - starttime;
		  let days = parseInt(timediff / 86400);
		  //计算小时数
		  let remain0 = timediff % 86400;
		  let hours = parseInt(remain0 / 3600);
		  //计算分钟数
		  let remain1 = remain0 % 3600;
		  let mins = parseInt(remain1 / 60);
		  if(days>=1){
			  return days + '天' + hours + '小时' + mins + '分';
		  }else{
			  return hours + '小时' + mins + '分';
		  }
		}
    }
}